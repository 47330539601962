import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import Sticky from 'react-stickynode';
import { DrawerProvider } from '../../contexts/DrawerContext';
import { saasModernTheme } from '../../assets/theme/saasModern';
import { ResetCSS } from '../../assets/css/style.js';
import {
  GlobalStyle,
  ContentWrapper
} from '../../containers/SaasModern/sassModern.style';
import { Modal } from '@redq/reuse-modal';
import Navbar from '../../containers/SaasModern/Navbar';
import Footer from '../../containers/SaasModern/Footer';
import SEO from '../../components/seo';

export default function Layout({ children }) {
  return (
    <ThemeProvider theme={saasModernTheme}>
      <Fragment>
        <SEO />
        <Modal />
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <div>{children}</div>
          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
}
