import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Text from '../../Text';
import Link from '../../Link';
import Image from '../../Image';
import Img from 'gatsby-image';

const Logo = ({
  logoWrapperStyle,
  logoStyle,
  titleStyle,
  withAchor,
  anchorProps,
  logoSrc,
  useWhiteLogo,
  title,
  ...props
}) => {
  const Data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "awaken/awaken_solutions_logo_400.png" }) {
        childImageSharp {
          fixed(width: 200, quality: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      logoWhite: file(
        relativePath: { eq: "awaken/awaken_solutions_logo_white_400.png" }
      ) {
        childImageSharp {
          fixed(width: 200, quality: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      logoFr: file(
        relativePath: { eq: "awaken/solutions_awaken_logo_400.png" }
      ) {
        childImageSharp {
          fixed(width: 200, quality: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      logoWhiteFr: file(
        relativePath: { eq: "awaken/solutions_awaken_logo_white_400.png" }
      ) {
        childImageSharp {
          fixed(width: 200, quality: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `);

  let lang = process.env.GATSBY_LANG;
  let logoFluid = lang === 'fr' ? Data.logoFr : Data.logo;
  if (useWhiteLogo) {
    logoFluid = lang === 'fr' ? Data.logoWhiteFr : Data.logoWhite;
  }
  return (
    <Link to="#" {...props} {...logoWrapperStyle}>
      {withAchor ? (
        <a {...anchorProps}>
          {logoFluid ? (
            <Img
              fixed={
                (logoFluid !== null) | undefined
                  ? logoFluid.childImageSharp.fixed
                  : {}
              }
              alt={title}
              fadeIn={false}
            />
          ) : (
            <Text content={title} {...titleStyle} />
          )}
        </a>
      ) : (
        <>
          {logoFluid ? (
            <Img
              fixed={
                (logoFluid !== null) | undefined
                  ? logoFluid.childImageSharp.fixed
                  : {}
              }
              alt={title}
              fadeIn={false}
            />
          ) : (
            <Text content={title} {...titleStyle} />
          )}
        </>
      )}
    </Link>
  );
};

Logo.propTypes = {
  logoSrc: PropTypes.string,
  title: PropTypes.string.isRequired,
  logoWrapperStyle: PropTypes.object,
  logoStyle: PropTypes.object,
  titleStyle: PropTypes.object,
  withAchor: PropTypes.bool,
  anchorProps: PropTypes.object
};

Logo.defaultProps = {
  logoWrapperStyle: {
    display: 'inline-block',
    mr: '1rem',
    'a:hover,a:focus': {
      textDecoration: 'none'
    }
  },
  titleStyle: {
    display: 'inline-block',
    fontSize: '2rem',
    lineHeight: 'inherit',
    whiteSpace: 'nowrap'
  }
};
export default Logo;
