import React from 'react';
// import Helmet from 'react-helmet';

class CalendlyEmbed extends React.Component {
  calendlyScriptSrc = 'https://assets.calendly.com/assets/external/widget.js';
  buildCalendlyUrl = (account, eventName) =>
    `https://calendly.com/${account}/${eventName}?hide_event_type_details=1&primary_color=f16600`;

  componentDidMount() {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute('src', this.calendlyScriptSrc);
    // <Helmet>
    //   <script src={script} />
    // </Helmet>;
    if (head) {
      head.appendChild(script);
    }
  }

  componentWillUnmount() {
    const head = document.querySelector('head');
    const script = document.querySelector('script');
    // Added if due to error: Failed to execute 'removeChild' on 'Node'
    // Then just hid this since still gave errors sometimes
    // if (head) {
    //   head.removeChild(script);
    // }
  }
  render() {
    const { account, eventName } = this.props;
    return (
      <div id="calendlyWrapper">
        <div
          className="calendly-inline-widget"
          data-url={this.buildCalendlyUrl(account, eventName)}
          style={{ minWidth: '320px', height: '500px' }}
        />
      </div>
    );
  }
}

export default CalendlyEmbed;
