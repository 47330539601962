import styled from 'styled-components';
import { themeGet } from 'styled-system';

const LoginModalWrapper = styled.div`
  width: 80%;
  margin: 0 auto;
  border-radius: 5px;
  overflow: hidden;
  background-color: ${themeGet('colors.white', '#ffffff')};
  @media only screen and (min-width: 1201px) {
    // max-width: 1170px;
    width: 100%;
    // margin: 0 auto;
  }
  @media only screen and (max-width: 667px) {
    width: 100%;
  }
  .col {
    position: relative;
    .patternImage {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    @media only screen and (max-width: 991px) {
      width: 100%;
      &.imageCol {
        display: none;
      }
    }
  }
  .reusecore__button {
    background-color: transparent;
    &.default {
      background-color: ${themeGet('colors.primary', '#10ac84')};
      transition: all 0.3s ease;
      &:hover {
        box-shadow: 0px 9px 20px -5px rgba(0, 0, 0, 0.3);
      }
    }
  }
  h2.titleStyle {
    font-weight: 400;
    color: #333;
    letterspacing: -0.025em;
    margin-top: 35px;
    margin-bottom: 10px;
    text-align: center;
    border-bottom: 1px solid #f16600;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  .emailPhone {
    a {
      display: block;
      text-align: center;
      font-size: 20px;
      line-height: 28px;
      padding: 15px;
      color: #f16600;
      font-weight: 400;
    }
  }
  .successBox {
    background-color: #e3f5e3;
    border-radius: 15px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.16);
    p {
      text-align: center;
      font-size: 20px;
      line-height: 28px;
      padding: 15px;
      color: #2b502b;
      font-weight: 400;
    }
  }
  .errorBox {
    background-color: #f4e3e3;
    border-radius: 15px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.16);
    p {
      text-align: center;
      font-size: 20px;
      line-height: 28px;
      padding: 15px;
      color: #d81c1c;
      font-weight: 400;
    }
  }

  .rc-tabs {
    border: 0;
    margin: 0 0 0;
    @media only screen and (max-width: 991px) {
      max-width: 100%;
    }
    .rc-tabs-nav-container {
      padding: 0;
      .rc-tabs-tab-prev,
      .rc-tabs-tab-next {
        display: none;
      }
      .rc-tabs-nav-scroll,
      .rc-tabs-nav {
        width: 100%;
        .rc-tabs-tab {
          width: 50%;
          margin-right: 0;
          padding: 13px 0;
          text-align: center;
        }
      }
      .rc-tabs-tab:hover {
        color: #f16600 !important;
      }
      .rc-tabs-tab-active {
        color: #f16600 !important;
      }
      .rc-tabs-ink-bar {
        background-color: #f16600 !important;
      }
    }
    .rc-tabs-tabpane {
      padding-left: 15px;
      padding-bottom: 15px;
      padding-right: 15px;
      @media (min-width: 1200px) {
        min-height: 560px;
      }
    }
    .google-login__btn {
      width: 100%;
      font-size: 15px;
      font-weight: 700;
      margin-bottom: 45px;
      box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
      .btn-icon {
        position: relative;
        left: -22px;
        img {
          width: 21px;
          height: auto;
        }
      }
    }
    .reusecore__input {
      margin-bottom: 30px;
      &.is-material {
        &.is-focus {
          label {
            color: ${themeGet('colors.primary', '#10ac84')};
            top: -12px;
          }
          .highlight {
            background-color: ${themeGet('colors.primary', '#10ac84')};
          }
        }
      }

      label {
        font-weight: 400;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.6);
        top: 15px;
      }
    }
    .reusecore__checkbox {
      margin: 0 0 35px;
      label {
        .reusecore__field-label {
          font-size: 13px;
          font-weight: 400;
        }
      }
    }
  }
  .contactRow {
    // padding-top: 35px;
    // padding-bottom: 40px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  #calendlyWrapper {
    border: 1px solid #eee;
    margin-bottom: 50px;
  }
`;

export default LoginModalWrapper;
