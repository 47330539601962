import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '../../../components/Box';
import Heading from '../../../components/Heading';
import Input from '../../../components/Input';
import Text from '../../../components/Text';
import Button from '../../../components/Button';
import LoginModalWrapper from './loginModal.style';
import Container from '../../../components/UI/Container';
import CalendlyEmbed from './../../../components/CalendlyEmbed';
import 'rc-tabs/assets/index.css';
import Helmet from 'react-helmet';
import { enFr } from '../../../utils';

const LoginModal = ({
  btnStyle,
  logoStyle,
  titleStyle,
  contentWrapper,
  outlineBtnStyle,
  descriptionStyle,
  googleButtonStyle,
  inputField,
  row,
  col
}) => {
  const [displayForm, setDisplayForm] = useState('block');
  const [displaySuccess, setDisplaySuccess] = useState('none');
  const [displayError, setDisplayError] = useState('none');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  let lang = process.env.GATSBY_LANG;
  if (lang === 'fr') {
    titleStyle = {
      fontSize: ['22px', '22px', '30px']
    };
  }

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
      )
      .join('&');
  };

  const handleSubmit = () => {
    if (!name || !email || !message) {
      setDisplayError('block');
    } else {
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({ 'form-name': 'contact', name, email, phone, message })
      })
        .then(() => {
          setDisplayForm('none');
          setDisplaySuccess('block');
        })
        .catch((error) => {
          setDisplayError('block');
        });
    }
  };

  const SignupButtonGroup = () => (
    <Fragment>
      <Button
        className="default"
        title={enFr('SEND MESSAGE', 'ENVOYER')}
        onClick={() => {
          handleSubmit();
        }}
        {...btnStyle}
      />
    </Fragment>
  );
  return (
    <div>
      <Helmet
        bodyAttributes={{
          class: 'modalOpen'
        }}
      />
      <LoginModalWrapper>
        <Box {...contentWrapper}>
          <Box {...row}>
            <Box {...col}>
              <Heading
                content={enFr('Email or Call', 'Courriel ou Tél.')}
                {...titleStyle}
                className="titleStyle"
              />
              {/* <Text content="Give us a call or email:" {...descriptionStyle} /> */}
              <div className="emailPhone">
                <a
                  className="orangeLink"
                  href={enFr(
                    'mailto:info@awakensolutions.com',
                    'mailto:info@solutionsawaken.com'
                  )}
                >
                  {enFr('info@awakensolutions.com', 'info@solutionsawaken.com')}
                </a>
                <a className="orangeLink" href="tel:+15149954967">
                  +1 514 995 4967
                </a>
              </div>
            </Box>
            <Box {...col}>
              <Heading
                content={enFr('Send a message', 'Envoyez un message')}
                {...titleStyle}
                className="titleStyle"
              />
              <Box style={{ display: displayForm }}>
                <form name="contact" method="POST" data-netlify="true">
                  <input type="hidden" name="form-name" value="contact" />
                  <Input
                    isMaterial
                    label={enFr('Name *', 'Nom *')}
                    onChange={(value) => {
                      setName(value);
                    }}
                    // value={name}
                    {...inputField}
                  />
                  <Input
                    inputType="email"
                    isMaterial
                    label={enFr('Email *', 'Courriel *')}
                    onChange={(value) => {
                      setEmail(value);
                    }}
                    // value={email}
                    {...inputField}
                  />
                  <Input
                    inputType="text"
                    isMaterial
                    label={enFr('Phone', 'Téléphone')}
                    onChange={(value) => {
                      setPhone(value);
                    }}
                    // value={phone}
                    {...inputField}
                  />
                  <Input
                    inputType="textarea"
                    // cols="50"
                    rows="5"
                    isMaterial
                    label="Message *"
                    onChange={(value) => {
                      setMessage(value);
                    }}
                    // value={message}
                    {...inputField}
                  />
                </form>
                <div style={{ marginTop: 30, marginBottom: 50 }}>
                  <SignupButtonGroup />
                </div>
              </Box>
              <Box style={{ display: displaySuccess }} className="successBox">
                <p>
                  {enFr(
                    "Thanks for reaching out. We'll get back to you within one business day.",
                    "Merci de nous avoir contacté! Nous vous répondrons dans un délai d'un jour ouvrable"
                  )}
                </p>
              </Box>
              <Box style={{ display: displayError }} className="errorBox">
                <p>
                  {enFr(
                    'Sorry there was an error submitting your message. Please ensure all required fields are completed, and try again. If this issue persists, please call or email us.',
                    "Désolé, une erreur s'est produite lors de la soumission de votre message. Veuillez vous assurer que tous les champs obligatoires sont remplis et réessayer. Si ce problème persiste, veuillez nous appeler ou nous envoyer un courriel."
                  )}
                </p>
              </Box>
            </Box>
            <Box {...col}>
              <Heading
                content={enFr('Schedule a call', 'Planifiez un appel')}
                {...titleStyle}
                className="titleStyle"
              />
              <CalendlyEmbed account="awaken-solutions" eventName="15min" />
            </Box>
          </Box>
        </Box>
      </LoginModalWrapper>
    </div>
  );
};

// LoginModal style props
LoginModal.propTypes = {
  logoStyle: PropTypes.object,
  titleStyle: PropTypes.object,
  hintTextStyle: PropTypes.object,
  contentWrapper: PropTypes.object,
  descriptionStyle: PropTypes.object,
  googleButtonStyle: PropTypes.object,
  inputField: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object
};

// LoginModal default style
LoginModal.defaultProps = {
  // Team member row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap'
  },
  // Team member col default style
  col: {
    width: [1, 1, 1, 1, 1 / 3],
    pl: [0, 0, 0, 0, '15px'],
    pr: [0, 0, 0, 0, '15px']
  },
  // Default logo size
  logoStyle: {
    width: '128px',
    height: 'auto',
    ml: '15px'
  },
  // Title default style
  titleStyle: {
    fontSize: ['22px', '22px', '36px']
  },
  inputField: {
    pt: '35px',
    pb: '10px',
    mt: '35px',
    mb: '10px'
  },
  // Description default style
  descriptionStyle: {
    color: 'rgba(52, 61, 72, 0.8)',
    fontSize: '18px',
    lineHeight: '30px',
    mt: '23px',
    mb: '0',
    textAlign: 'left'
  },
  // Content wrapper style
  contentWrapper: {
    pt: ['0', '0'],
    pl: '32px',
    pr: '32px',
    pb: ['0', '0']
  },
  // Default button style
  btnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500'
  },
  // Outline button outline style
  outlineBtnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
    color: '#10ac84'
  },
  // Google button style
  googleButtonStyle: {
    bg: '#ffffff',
    color: '#343D48'
  }
};

export default LoginModal;
