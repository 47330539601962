const colors = {
  transparent: 'transparent', // 0
  black: '#000000', // 1
  white: '#ffffff', // 2
  headingColor: '#302b4e',
  textColor: '#676767', // 3  was 43414e
  labelColor: '#767676', // 4
  inactiveField: '#f2f2f2', // 5
  inactiveButton: '#b7dbdd', // 6
  inactiveIcon: '#EBEBEB', // 7
  primary: '#f16600', // 8  was 2aa275
  primaryHover: '#e66001', // 9  was 2aa275
  secondary: '#333333', // 10  was edcd37
  secondaryHover: '#222222', // 11  was edcd37 also
  yellow: '#fdb32a', // 12
  yellowHover: '#F29E02', // 13
  borderColor: '#1b1e25', //14
  primaryBoxShadow: '0px 8px 20px -6px rgba(0, 0 , 0, 0.5)', // was 0px 8px 20px -6px rgba(42, 162, 117, 0.57)
  secondaryBoxShadow: '0px 8px 20px -6px rgba(0, 0 , 0, 0.5)', // was 0px 8px 20px -6px rgba(237, 205, 55, 0.5)
  lightGreyBkg: '#f8f8f8',
  niceBlack: '#333333' // (same as secondary)
};

export default colors;
