import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body{
    font-family: 'Open Sans', sans-serif;
  }
  body.modalOpen {
    overflow: hidden;
  }


  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Open Sans', sans-serif;
  }

  section {
    position: relative;
  }

  .drawer-content-wrapper{
    @media (max-width: 767px) {
      width: 300px!important;
    }
    .drawer-content {
      padding: 60px;    
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media (max-width: 767px) {
        padding: 50px 40px 30px 40px;
      }
      .mobile_menu {
        margin-bottom: 40px;
        flex-grow: 1;
        @media (max-width: 767px) {
          margin-bottom: 30px;
        }
        li{
          margin-bottom: 35px;
          @media (max-width: 767px) {
            margin-bottom: 25px;
          }
          a{
            font-size: 20px;
            font-weight: 400;
            color: #343d48;
            position: relative;
            transition: 0.15s ease-in-out;
            @media (max-width: 767px) {
              font-size: 18px;
            }
            &:hover {
              color: #f16600;
            }
            &:before{
              content: '';
              width: 7px;
              height: 7px;
              background: #f16600;
              border-radius: 50%;
              position: absolute;
              top: 50%;
              left: -15px;
              transform: translateY(-50%);
              opacity: 0;
            }
          }
          &.is-current {
            a {
              color: #f16600;
              &:before{
                opacity: 1;
              }
            }
          }
        }
      }
      .drawer_lang {
        text-align: center;
        margin-top: 20px;
        color: #f16600;
        text-transform: uppercase;
        font-size: 13px;
      }
      .navbar_drawer_button button{
        width: 100%;
      }
    }

    .reusecore-drawer__close{
      width: 34px;
      height: 34px;
      position: absolute;
      top: 20px;
      right: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      @media (max-width: 767px) {
        top: 15px;
        right: 15px;
      }
      &:before{
        content: '\f10b';
        font-family: Flaticon;
        font-size: 26px;
        color: #f16600;
        transform: rotate(45deg);
        display: block;
      }
    }
  }

  /* Modal default style */
  button.modalCloseBtn {
    color: #000000 !important;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
    background-color: #ffffff !important;
    // box-shadow: 0 8px 38px rgba(0, 0, 0, 0.5) !important;
    &.alt {
      background-color: #10ac84 !important;
      box-shadow: 0 8px 38px rgba(16, 172, 132, 0.5) !important;
    }
  }
  .reuseModalHolder {
    border: 0 !important;
    background-color: transparent !important;
    &.search-modal,
    &.video-modal {
      background-color: rgba(255, 255, 255, 0.96) !important;
      overflow-y: auto !important;
      .innerRndComponent {
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        iframe {
          max-width: 700px !important;
          max-height: 380px !important;
          width: 100% !important;
          height: 100% !important;
          border-radius: 5px !important;
        }
      }
    }
    &.demo_switcher_modal {
      border: 0 !important;
      background-color: rgba(16, 30, 77, 0.8) !important;
      .innerRndComponent {
        border-radius: 8px !important;
      }
    }
    &.video-modal {
      background-color: transparent !important;
    }
    .innerRndComponent {
      padding-right: 0 !important;
    }
  }
  .reuseModalCloseBtn {
    cursor: pointer !important;
  }

  .reuseModalOverlay,
  .reuseModalParentWrapper{
    z-index: 99999!important;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;    
  }

  .reuseModalOverlay {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;    
    bottom: 0;
    right: 0;
    content: "";
    background-color: rgba(0,0,0,.7);
    position: fixed;
  }

  .reuseModalHolder, .reuseModalParentWrapper {
    // display: flex;
    // overflow: hidden;
    overflow: overlay;
    box-sizing: border-box;
  }
  .reuseModalParentWrapper {
    position: fixed;
  }


  .reuseModalHolder.login-modal{
    top: 0px !important;
    width: 100% !important;
    max-width: 100% !important;
    height: fit-content !important;
    max-height: fit-content !important;
    left: inherit !important;
    @media (min-width: 768px) {
      top: 70px !important;
      left: inherit !important;
      width: 90% !important;
      max-width: inherit !important;
      position: relative !important;
      margin: 0 auto !important;
      min-height: 600px;
    }
  }

  .reuseModalHolder.search-modal{
    top: 0!important;
    left: 0!important;
    max-width: 100%!important;
    max-height: 100%!important;
    width: 100%;
    height: 100%;
  }

  .reuseModalHolder.login-modal .innerRndComponent{
    overflow-y: auto;
  }

  .field-wrapper {
    margin: 10px 0 25px 0;
  }

  .orangeLink {
    color: #f16600;
    &:hover {
      text-decoration: underline;
    }
  }

  .typing-wrapper {
    display: block;
    // height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .typing-demo {
    width: 21.2ch;
    animation: typing 2s steps(22), blink .5s step-end infinite alternate;
    white-space: nowrap;
    overflow: hidden;
    border-right: 3px solid;
    font-family: 'Open Sans', sans-serif;
    // font-family: monospace;
    font-size: 46px;
    color: #fff;
    text-shadow: 1px 1px 4px rgba(0,0,0,0.4);
  }
  
  @keyframes typing {
    from {
      width: 0
    }
  }
      
  @keyframes blink {
    50% {
      border-color: transparent
    }
  }
`;

export const ContentWrapper = styled.div`
  overflow: hidden;
  .main-logo {
    width: 200px;
  }
  .logo-alt {
    width: 200px;
    margin-top: 2px;
    margin-bottom: -2px;
    &.french {
      margin-top: 7px;
      margin-bottom: -7px;
    }
  }
  .one-percent {
    img {
      width: 80px;
      margin-top: 15px;
    }
  }
  .sticky-nav-active {
    .saas_navbar {
      .navbar_button {
        display: block;
        @media (max-width: 990px) {
          display: none;
        }
      }
      .navbar_button_plain_text {
        color: #333;
      }
      .navbar_button_plain_text.contact {
        display: none;
      }
      background: #fff;
      box-shadow: 0px 3px 8px 0px rgba(43, 83, 135, 0.08);
      padding: 5px 0;
      .main-logo {
        display: none;
      }
      .logo-alt {
        display: block;
      }
      .main_menu {
        li {
          a {
            color: #333;
            &:hover {
              color: #f16600;
            }
          }
          &.is-current {
            a {
              color: #f16600;
            }
          }
        }
      }
    }
    .hamburgMenu__bar {
      > span {
        background: #f16600;
      }
    }
  }

  .saas_navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    transition: 0.35s ease-in-out;
    padding: 30px 0;
    .logo-alt {
      display: none;
    }
    .main_menu {
      margin-right: 25px;
      li {
        display: inline-block;
        padding-left: 13px;
        padding-right: 13px;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
        &.is-current {
          a {
            color: #fff;
          }
        }
        a {
          padding: 5px;
          font-size: 16px;
          font-weight: 400;
          color: #fff;
          transition: 0.15s ease-in-out;
          &:hover {
            color: #f9cd26;
          }
        }
      }
      @media (max-width: 990px) {
        display: none;
      }
    }
    .navbar_button {
      display: none;
    }
    .navbar_button_plain_text {
      padding: 5px;
      font-size: 16px;
      font-weight: 400;
      color: #fff;
      cursor: pointer;
      transition: 0.15s ease-in-out;
      margin-right: 20px;
      @media (max-width: 990px) {
        display: none;
      }
      &:hover {
        color: #f9cd26;
      }
      &.contact {
        margin-right: 30px;
        @media (max-width: 990px) {
          display: none;
        }
      }
    }
    .reusecore-drawer__handler {
      @media (min-width: 991px) {
        display: none !important;
      }
      .hamburgMenu__bar {
        > span {
        }
      }
    }
  }

  /* Awaken awk CSS*/
  .separator {
    @media (max-width: 575px) {
      height: 70px;
      background-color: #f8f8f8;
    }
  }
`;
