import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Box from '../../../components/Box';
import Text from '../../../components/Text';
import Heading from '../../../components/Heading';
import Logo from '../../../components/UIElements/Logo';
import Container from '../../../components/UI/Container';
import FooterWrapper, { List, ListItem } from './footer.style';
import { Link } from 'gatsby';
import { openModal, closeModal } from '@redq/reuse-modal';
import LoginModal from '../LoginModal';
import Icon from 'react-icons-kit';
import { ic_phone } from 'react-icons-kit/md/ic_phone';
import Button from '../../../components/Button';
import Img from 'gatsby-image';
import { enFr } from '../../../utils';
import OnePercentLogo from '../../../assets/images/awaken/1_percent_for_the_planet_600.png';

import LogoImage from '../../../assets/images/awaken/awaken_solutions_logo_400.png';
// Default close button for modal
const CloseModalButton = () => (
  <Button
    className="modalCloseBtn"
    variant="fab"
    onClick={() => closeModal()}
    icon={<i className="flaticon-plus-symbol" />}
  />
);

const Footer = ({
  row,
  col,
  colOne,
  colTwo,
  titleStyle,
  logoStyle,
  textStyle,
  linkStyle,
  fillButton,
  buttonWrapper
}) => {
  const Data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "awaken/awaken_slider_hands_1920_tiny.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const handleLoginModal = () => {
    openModal({
      config: {
        className: 'login-modal',
        disableDragging: true,
        default: {
          width: '80%',
          height: 300,
          margin: 0
        },
        margin: 0
      },
      component: LoginModal,
      componentProps: {},
      closeComponent: CloseModalButton,
      closeOnClickOutside: true
    });
  };

  return (
    <FooterWrapper>
      <Container className="footer_container">
        <Box className="row" {...row}>
          <Box {...colOne} className="colOne">
            <Logo
              href="#welcome"
              useWhiteLogo={false}
              // logoSrc={LogoImageAlt}
              title="Awaken Solutions"
              logoStyle={logoStyle}
            />
            <div style={{ marginTop: 15 }}>
              <a
                className="orangeLink"
                href={enFr(
                  'mailto:info@awakensolutions.com',
                  'mailto:info@solutionsawaken.com'
                )}
              >
                {enFr('info@awakensolutions.com', 'info@solutionsawaken.com')}
              </a>
            </div>
            <div style={{ marginTop: 10 }}>
              <a className="orangeLink" href="tel:+15149954967">
                +1 514 995 4967
              </a>
            </div>
            <div className="one-percent">
              <a
                href="https://directories.onepercentfortheplanet.org/business-members/awaken-solutions-inc"
                target="_blank"
              >
                <img src={OnePercentLogo} alt={`1% for the Planet`} />
              </a>
            </div>
          </Box>
          {/* End of footer logo column */}
          <Box {...colTwo} className="colTwo">
            <Heading
              content={enFr(
                'A 15-MINUTE CALL CAN GO A LONG WAY',
                'UN APPEL DE 15 MINUTES PEUT ALLER LOIN'
              )}
              {...titleStyle}
            />
            <Text
              content={enFr(
                "In our 20+ years working with clients from around the world, we've found that a simple 15-minute phone call is the most efficient way to understand your needs and see if our services are a good fit for your project.",
                "Dans nos 20+ ans de travails avec des clients du monde entier, nous avons constaté qu'un simple appel téléphonique de 15 minutes est le moyen le plus efficace de comprendre vos besoins et de voir si nos services sont bien alignés avec votre projet."
              )}
              {...textStyle}
            />
            <Box {...buttonWrapper}>
              <a href="#1">
                <Button
                  {...fillButton}
                  title={enFr(
                    'SCHEDULE A 15-MIN CALL',
                    'PRENEZ UN RENDEZ-VOUS TÉLÉPHONIQUE'
                  )}
                  icon={<Icon icon={ic_phone} size={30} />}
                  iconPosition="left"
                  onClick={handleLoginModal}
                />
              </a>
            </Box>
          </Box>
        </Box>
      </Container>
    </FooterWrapper>
  );
};

// Footer style props
Footer.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  colOne: PropTypes.object,
  colTwo: PropTypes.object,
  titleStyle: PropTypes.object,
  textStyle: PropTypes.object,
  linkStyle: PropTypes.object,
  logoStyle: PropTypes.object,
  fillButton: PropTypes.object,
  buttonWrapper: PropTypes.object
};

// Footer default style
Footer.defaultProps = {
  // Footer row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px'
  },
  // Footer col one style
  colOne: {
    width: [1, '35%', '35%', '23%'],
    mt: [0, '13px'],
    mb: ['30px', 0],
    pl: ['15px', 0],
    pr: ['15px', '15px', 0]
  },
  // Footer col two style
  // colTwo: {
  //   width: ['100%', '65%', '65%', '77%'],
  //   flexBox: true,
  //   flexWrap: 'wrap'
  // },
  colTwo: {
    width: ['100%', '65%', '65%', '77%'],
    pl: ['10px', '15px', '15px', '15px'],
    pr: ['10px', '15px', '15px', '15px'],
    alignSelf: 'center',
    textAlign: 'center'
  },
  // Footer col default style
  col: {
    width: ['100%', '50%', '50%', '25%'],
    pl: '15px',
    pr: '15px',
    mb: '30px'
  },
  // widget title default style
  titleStyle: {
    color: '#343d48',
    fontSize: '16px',
    fontWeight: '700',
    mb: '15px',
    textAlign: 'center'
  },
  // Default logo size
  logoStyle: {
    width: '200px',
    mb: '15px'
  },
  // widget text default style
  textStyle: {
    display: 'block',
    color: '#777',
    fontSize: '16px',
    mb: '0px',
    textAlign: 'center'
  },
  linkStyle: {
    color: '#f16600',
    fontSize: '16px',
    mb: '10px',
    'a:hover,a:focus': {
      textDecoration: 'underline',
      color: 'red !important'
    }
  },
  fillButton: {
    type: 'button',
    fontSize: ['13px', '14px'],
    fontWeight: '700',
    borderRadius: '4px',
    p: ['0px 15px', '8px 22px'],
    colors: 'primaryWithBg',
    minWidth: ['auto', '150px'],
    height: ['40px', '46px'],
    minHeight: 'auto'
  },
  buttonWrapper: {
    flexBox: true,
    justifyContent: 'center',
    mt: '20px'
  }
};

export default Footer;
