import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import NavbarWrapper from '../../../components/Navbar';
import Drawer from '../../../components/Drawer';
import Button from '../../../components/Button';
import Logo from '../../../components/UIElements/Logo';
import Box from '../../../components/Box';
import HamburgMenu from '../../../components/HamburgMenu';
import Container from '../../../components/UI/Container';
import { DrawerContext } from '../../../contexts/DrawerContext';
import ScrollSpyMenu from '../../../components/ScrollSpyMenu';
import { openModal, closeModal } from '@redq/reuse-modal';
import LoginModal from '../LoginModal';
import { Link } from 'gatsby';
import { enFr } from '../../../utils';

// Default close button for modal
const CloseModalButton = () => (
  <Button
    className="modalCloseBtn"
    variant="fab"
    onClick={() => closeModal()}
    icon={<i className="flaticon-plus-symbol" />}
  />
);

const Navbar = ({ navbarStyle, logoStyle, button, row, menuWrapper }) => {
  const Data = useStaticQuery(graphql`
    query {
      awakenJson {
        MENU_ITEMS {
          label
          path
          offset
        }
      }
      awakenFrenchJson {
        MENU_ITEMS {
          label
          path
          offset
        }
      }
    }
  `);

  let lang = process.env.GATSBY_LANG;
  let localizedData = Data.awakenJson;
  if (lang === 'fr') {
    localizedData = Data.awakenFrenchJson;
  }

  const { state, dispatch } = useContext(DrawerContext);

  // Toggle drawer
  const toggleHandler = () => {
    dispatch({
      type: 'TOGGLE'
    });
  };
  const handleLoginModal = () => {
    openModal({
      config: {
        className: 'login-modal',
        disableDragging: true,
        default: {
          width: '80%',
          height: 300,
          margin: 0
        },
        margin: 0
      },
      component: LoginModal,
      componentProps: {},
      closeComponent: CloseModalButton,
      closeOnClickOutside: true
    });
  };

  return (
    <NavbarWrapper {...navbarStyle} className="saas_navbar">
      <Container>
        <Box {...row}>
          <Logo
            href="#welcome"
            useWhiteLogo={true}
            // logoSrc={LogoImage}
            title="Awaken Solutions"
            logoStyle={logoStyle}
            className={`main-logo ${lang === 'fr' && 'french'}`}
          />
          <Logo
            href="#welcome"
            useWhiteLogo={false}
            // logoSrc={LogoImageAlt}
            title="Awaken Solutions"
            logoStyle={logoStyle}
            className={`logo-alt ${lang === 'fr' && 'french'}`}
          />
          <Box {...menuWrapper}>
            <ScrollSpyMenu
              className="main_menu"
              menuItems={localizedData.MENU_ITEMS}
              offset={-70}
            />
            <a
              href={enFr(
                'https://www.solutionsawaken.com',
                'https://www.awakensolutions.com'
              )}
              className="navbar_button_plain_text"
            >
              {enFr('Français', 'English')}
            </a>
            <Link className="navbar_button">
              <Button
                {...button}
                title={enFr('CONTACT US', 'CONTACTEZ-NOUS')}
                onClick={handleLoginModal}
              />
            </Link>
            <a
              onClick={handleLoginModal}
              className="navbar_button_plain_text contact"
            >
              {enFr('Contact Us', 'Contactez-nous')}
            </a>
            <Drawer
              width="420px"
              placement="right"
              drawerHandler={<HamburgMenu barColor="#fff" />}
              open={state.isOpen}
              toggleHandler={toggleHandler}
            >
              <ScrollSpyMenu
                className="mobile_menu"
                menuItems={Data.awakenJson.MENU_ITEMS}
                drawerClose={true}
                offset={-100}
              />
              <a href="#1" className="navbar_drawer_button">
                <Button
                  {...button}
                  title={enFr('CONTACT US', 'CONTACTEZ-NOUS')}
                  onClick={() => {
                    toggleHandler();
                    handleLoginModal();
                  }}
                />
              </a>
              <a
                href={enFr(
                  'https://www.solutionsawaken.com',
                  'https://www.awakensolutions.com'
                )}
                className="drawer_lang"
              >
                {enFr('Français', 'English')}
              </a>
            </Drawer>
          </Box>
        </Box>
      </Container>
    </NavbarWrapper>
  );
};

Navbar.propTypes = {
  navbarStyle: PropTypes.object,
  logoStyle: PropTypes.object,
  button: PropTypes.object,
  row: PropTypes.object,
  menuWrapper: PropTypes.object
};

Navbar.defaultProps = {
  navbarStyle: {
    minHeight: '70px',
    display: 'block'
  },
  row: {
    flexBox: true,
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  logoStyle: {
    // width: ['300px', '300px'],
    // maxWidth: ['170px', '200px']
  },
  button: {
    type: 'button',
    fontSize: '13px',
    fontWeight: '700',
    borderRadius: '4px',
    pl: '15px',
    pr: '15px',
    colors: 'primaryWithBg',
    minHeight: 'auto',
    height: '40px'
  },
  menuWrapper: {
    flexBox: true,
    alignItems: 'center'
  }
};

export default Navbar;
